import { RouteRecordRaw } from "vue-router";
import configuration from "./routerConfiguration";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "loGin",
    component: () => import("../views/loGin.vue"),
  },
  {
    path: "/homePage",
    name: "homePage",
    redirect: "/homePageS",
    component: () => import("../views/homePage.vue"),
    children: [
      {
        path: "/homePageS",
        name: "homePage",
        component: () => import("../views/homePageS.vue"),
        meta: {
          id: 0,
        },
      },
      {
        path: "/home",
        name: "home",
        component: () => import("../views/HomeView.vue"),
        redirect: "/AboutView",
        meta: {
          id: 136,
          type: "基础数据",
        },
        children: [
          {
            path: "/AboutView",
            name: "AboutView",
            meta: {
              title: "学期管理",
              show: false,
              icon: "xueqi",
              value: configuration.semester,
            },
            component: () => import("../views/basicdata/AboutView.vue"),
          },
          {
            path: "/stuSutA",
            name: "stuSutA",
            meta: {
              title: "年级管理",
              show: false,
              icon: "nianji",
              value: configuration.grade,
            },
            component: () => import("../views/basicdata/stuSutA.vue"),
          },
          {
            path: "/teacher",
            name: "teacher",
            meta: {
              title: "专业管理",
              // more: true,
              show: false,
              icon: "zhuanye",
              // value: 198,
            },
            children: [
              {
                path: "/teacherA",
                name: "teacherA",
                meta: {
                  title: "专业分类",
                  icon: "yidongyunkongzhitaiicon01",
                  show: false,
                  value: configuration.majorclassify,
                },
                component: () => import("../views/basicdata/teacherA.vue"),
              },
              {
                path: "/teacherB",
                name: "teacherB",
                meta: {
                  title: "专业维护",
                  icon: "yidongyunkongzhitaiicon01",
                  show: false,
                  value: configuration.major,
                },
                component: () => import("../views/basicdata/teacherB.vue"),
              },
            ],
          },
          {
            path: "/officeBuilding",
            name: "officeBuilding",
            meta: {
              title: "场地管理",
              // more: true,
              show: false,
              icon: "changdi",
              // value: "",
            },
            children: [
              {
                path: "/officeBuilding",
                name: "officeBuilding",
                meta: {
                  title: "楼栋维护",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.building,
                },
                component: () =>
                  import("../views/basicdata/officeBuilding.vue"),
              },
              {
                path: "/classRoom",
                name: "classRoom",
                meta: {
                  title: "房间维护",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.buildingroom,
                },
                component: () => import("../views/basicdata/classRoom.vue"),
              },
            ],
          },
          {
            path: "/adminiStration",
            name: "adminiStration",
            meta: {
              title: "班级管理",
              show: false,
              icon: "banji",
              value: configuration.classes,
            },
            component: () => import("../views/userpower/adminiStration.vue"),
          },
          {
            path: "/stuDent",
            name: "stuDent",
            meta: {
              title: "学生信息管理",
              show: false,
              icon: "xuesheng",
              value: configuration.student,
            },
            component: () => import("../views/basicdata/stuDent.vue"),
          },
          {
            path: "/inFormation",
            name: "inFormation",

            meta: {
              title: "教职工信息管理",
              icon: "jaiozhigong",
              show: false,
              value: configuration.teacher,
            },
            component: () => import("../views/basicdata/inFormation.vue"),
          },
        ],
      },
      {
        path: "/asdAsd",
        name: "asdAsd",
        redirect: "/loGing",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 340,
          type: "用户及授权管理",
          icon: "yidongyunkongzhitaiicon01",
        },
        children: [
          {
            path: "/loGing",
            name: "loGing",
            meta: {
              title: "登录账户管理",
              show: false,
              icon: "denglu",
              value: configuration.admin,
            },
            component: () => import("../views/userpower/loGing.vue"),
          },
          {
            path: "/roleManagement",
            name: "roleManagement",
            meta: {
              title: "角色管理",
              show: false,
              icon: "juese",
              value: configuration.adminroles,
            },
            component: () => import("../views/userpower/roleManagement.vue"),
          },

          {
            path: "/organization",
            name: "organization",
            meta: {
              title: "机构管理",
              show: false,
              icon: "jigou",
            },
            children: [
              {
                path: "/dimenSionality",
                name: "dimenSionality",
                meta: {
                  title: "组织机构维度维护",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.admindimension,
                },
                component: () =>
                  import("../views/basicdata/dimenSionality.vue"),
              },
              {
                path: "/mainTain",
                name: "mainTain",
                meta: {
                  title: "组织机构维护",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.adminorg,
                },
                component: () => import("../views/userpower/mainTain.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/smartClassroom",
        name: "smartClassroom",
        redirect: "/smartClassroomAll",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 388,
          type: "数字教室",
        },
        children: [
          {
            path: "/messageadministration",
            name: "messageadministration",
            component: () =>
              import("../views/smartClassroom/messageadmini.vue"),
            meta: {
              title: "数字教室信息管理",
              show: false,
              icon: "zhihuijiaoshi",
              value: configuration.tuyahomeroom,
            },
          },
          {
            path: "/earlywarning",
            name: "earlywarning",
            meta: {
              title: "预警管理",
              show: false,
              icon: "yujin",
            },
            children: [
              {
                path: "/earlywarningrule",
                name: "earlywarningrule",
                meta: {
                  title: "预警规则管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.tuyawarnset,
                },
                component: () =>
                  import("../views/smartClassroom/earlywarningrule.vue"),
              },
              {
                path: "/earlymessage",
                name: "earlymessage",
                meta: {
                  title: "预警信息统计",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.tuyawarn,
                },
                component: () =>
                  import("../views/smartClassroom/earlymessage.vue"),
              },
            ],
          },
          {
            path: "/strategyadministration",
            name: "strategyadministration",
            component: () =>
              import("../views/smartClassroom/strategyadministration.vue"),
            meta: {
              title: "策略管理",
              show: false,
              icon: "cel",
              value: configuration.tuyasencerulepool,
            },
          },
          {
            path: "/executeadministra",
            name: "executeadministra",
            component: () =>
              import("../views/smartClassroom/executeadministra.vue"),
            meta: {
              title: "执行管理",
              show: false,
              icon: "zhixing",
              value: configuration.tuyasencerulepoolaction,
            },
          },
          {
            path: "/devicemanagement",
            name: "devicemanagement",
            component: () =>
              import("../views/smartClassroom/devicemanagement.vue"),
            meta: {
              title: "设备管理",
              show: false,
              icon: "shebei",
              value: configuration.tuyadevice,
            },
          },
          {
            path: "/classLargescreen",
            name: "classLargescreen",
            component: () =>
              import("../views/smartClassroom/classLargescreen.vue"),
            meta: {
              value: "",
              title: "数据大屏",
              show: true,
              icon: "daping",
              large: "classLargescreen",
            },
          },
        ],
      },
      {
        path: "/smartClassrooms",
        name: "smartClassrooms",
        redirect: "/smartClassroomAlls",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 1734,
          type: "数字教室",
        },
        children: [
          {
            path: "/gatewayadministration",
            name: "gatewayadministration",
            component: () =>
              import("../views/smartClassrooms/gatewayadministration.vue"),
            meta: {
              title: "网关管理",
              show: false,
              icon: "cel",
              value: configuration.leedarsonldsdevice,
            },
          },
          {
            path: "/strategyadministrations",
            name: "strategyadministrations",
            component: () =>
              import("../views/smartClassrooms/strategyadministrations.vue"),
            meta: {
              title: "策略管理",
              show: false,
              icon: "cel",
              value: configuration.leedarsonldsstrategypool,
            },
          },
          {
            path: "/executeadministras",
            name: "executeadministras",
            component: () =>
              import("../views/smartClassrooms/executeadministras.vue"),
            meta: {
              title: "执行管理",
              show: false,
              icon: "zhixing",
              value: configuration.leedarsonldsstrategyroom,
            },
          },
          {
            path: "/devicemanagements",
            name: "devicemanagements",
            component: () =>
              import("../views/smartClassrooms/devicemanagements.vue"),
            meta: {
              title: "设备管理",
              show: false,
              icon: "shebei",
              value: configuration.leedarsonldsdevice,
            },
          },
        ],
      },
      {
        path: "/trainingroom",
        name: "trainingroom",
        redirect: "/smartClassroomAll",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 1327,
          type: "数字实训室",
        },
        children: [
          {
            path: "/wisdomtrainingroomdataAnalysis",
            name: "wisdomtrainingroomdataAnalysis",
            component: () =>
              import(
                "../views/wisdomtrainingroom/wisdomtrainingroomdataAnalysis.vue"
              ),
            meta: {
              value: "",
              title: "数据分析",
              show: false,
              icon: "shujufenxi",
            },
          },
          {
            path: "/trainingroomadministration",
            name: "trainingroomadministration",
            meta: {
              title: "实训室管理",
              show: false,
              icon: "shixunshi",
            },
            children: [
              {
                path: "/equipmentmanagement",
                name: "equipmentmanagement",
                component: () =>
                  import("../views/wisdomtrainingroom/equipmentmanagement.vue"),
                meta: {
                  value: configuration.generaltraining,
                  title: "实训室管理",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
              {
                path: "/equipmentmanagementtype",
                name: "equipmentmanagementtype",
                component: () =>
                  import(
                    "../views/wisdomtrainingroom/equipmentmanagementtype.vue"
                  ),
                meta: {
                  value: configuration.generaltrainingdevicecate,
                  title: "设备类型",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
              {
                path: "/equipmentmanagements",
                name: "equipmentmanagements",
                component: () =>
                  import(
                    "../views/wisdomtrainingroom/equipmentmanagements.vue"
                  ),
                meta: {
                  value: configuration.generaltrainingdevice,
                  title: "设备管理",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
              {
                path: "/equipmentearlywarning",
                name: "equipmentearlywarning",
                component: () =>
                  import(
                    "../views/wisdomtrainingroom/equipmentearlywarning.vue"
                  ),
                meta: {
                  value: configuration.generaltrainingdevicwarn,
                  title: "设备预警",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
            ],
          },
          {
            path: "/fittingsmanagement",
            name: "fittingsmanagement",
            meta: {
              title: "配件管理",
              show: false,
              icon: "caigou",
            },
            children: [
              {
                path: "/partspurchasing",
                name: "partspurchasing",
                component: () =>
                  import("../views/wisdomtrainingroom/partspurchasing.vue"),
                meta: {
                  value: configuration.generaldevicefaultpurchase,
                  title: "采购申请",
                  show: false,
                  icon: "caigou",
                },
              },
              {
                path: "/Purchaseapproval",
                name: "Purchaseapproval",
                component: () =>
                  import("../views/wisdomtrainingroom/Purchaseapproval.vue"),
                meta: {
                  value: configuration.generaldevicefaultpurchaseaudit,
                  title: "采购审批",
                  show: false,
                  icon: "caigou",
                },
              },
              {
                path: "/purchasinglist",
                name: "purchasinglist",
                component: () =>
                  import("../views/wisdomtrainingroom/purchasinglist.vue"),
                meta: {
                  value: configuration.generaldevicefaultpurchasegood,
                  title: "采购清单",
                  show: false,
                  icon: "caigou",
                },
              },
            ],
          },
          {
            path: "/equipmentmaintenancemanagement",
            name: "Equipmentmaintenancemanagement",
            meta: {
              title: "设备保养管理",
              show: false,
              icon: "shebei",
            },
            children: [
              {
                path: "/maintaintype",
                name: "maintaintype",
                component: () =>
                  import("../views/wisdomtrainingroom/maintaintype.vue"),
                meta: {
                  value: configuration.generaltrainingmaintenancenode,
                  title: "保养类型",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
              {
                path: "/maintainplan",
                name: "maintainplan",
                component: () =>
                  import("../views/wisdomtrainingroom/maintainplan.vue"),
                meta: {
                  value: configuration.generaltrainingplan,
                  title: "保养计划",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
              {
                path: "/maintaintask",
                name: "maintaintask",
                component: () =>
                  import("../views/wisdomtrainingroom/maintaintask.vue"),
                meta: {
                  value: configuration.generaltrainingtask,
                  title: "保养任务",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
              {
                path: "/generaltrainingtasklogs",
                name: "generaltrainingtasklogs",
                component: () =>
                  import(
                    "../views/wisdomtrainingroom/generaltrainingtasklogs.vue"
                  ),
                meta: {
                  value: configuration.generaltrainingtasklogs,
                  title: "保养记录",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
            ],
          },
          {
            path: "/maintenancemanagement",
            name: "maintenancemanagement",
            meta: {
              title: "设备报修管理",
              show: false,
              icon: "weixiu",
            },
            children: [
              {
                path: "/faultRepair",
                name: "faultRepair",
                component: () =>
                  import("../views/wisdomtrainingroom/faultRepair.vue"),
                meta: {
                  value: configuration.generaltrainingdevicefault,
                  title: "故障报修",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
              {
                path: "/repairOrdersubmit",
                name: "repairOrdersubmit",
                component: () =>
                  import("../views/wisdomtrainingroom/repairOrdersubmit.vue"),
                meta: {
                  value: configuration.generaltrainingdevicefaulthandle,
                  // title: "维修上报",
                  title: "故障处理",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
              {
                path: "/maintenanceLedger",
                name: "maintenanceLedger",
                component: () =>
                  import("../views/wisdomtrainingroom/maintenanceLedger.vue"),
                meta: {
                  value: configuration.generaltrainingdevicefaultlogs,
                  title: "维修记录",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
            ],
          },
          {
            path: "/toolcabinetmanagement",
            name: "toolcabinetmanagement",
            meta: {
              title: "智能工具柜管理",
              show: false,
              icon: "shebei",
            },
            children: [
              {
                path: "/cabinetgroupmanagement",
                name: "cabinetgroupmanagement",
                component: () =>
                  import(
                    "../views/wisdomtrainingroom/cabinetgroupmanagement.vue"
                  ),
                meta: {
                  value: configuration.generaltrainingcabinetgroup,
                  title: "工具柜组管理",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
              {
                path: "/toolLibrarymanagement",
                name: "toolLibrarymanagement",
                component: () =>
                  import(
                    "../views/wisdomtrainingroom/toolLibrarymanagement.vue"
                  ),
                meta: {
                  value: configuration.generaltraininglib,
                  title: "工具库管理",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
            ],
          },
          {
            path: "/accesscontrol",
            name: "accesscontrol",
            component: () =>
              import("../views/wisdomtrainingroom/accesscontrol.vue"),
            meta: {
              value: configuration.generaltraininggate,
              title: "门禁管理",
              show: false,
              icon: "jingbao2",
            },
          },
          {
            path: "/sxs",
            name: "sxs",
            component: () =>
              import(
                "../views/wisdomtrainingroom/wisdomtrainingroomScreen copy.vue"
              ),
            meta: {
              title: "数据大屏",
              show: true,
              icon: "daping",
              large: "sxs",
            },
          },
        ],
      },
      {
        path: "/wareHouse",
        name: "wareHouse",
        redirect: "/dataAnalysis",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 453,
          type: "仓储管理",
        },
        children: [
          {
            path: "/dataAnalysis",
            name: "dataAnalysis",
            component: () => import("../views/wareHouse/dataAnalysis.vue"),
            meta: {
              value: "",
              title: "数据分析",
              show: true,
              icon: "shujufenxi",
            },
          },
          {
            path: "/warningManage",
            name: "warningManage",
            component: () => import("../views/wareHouse/warningManage.vue"),
            meta: {
              value: configuration.warehousewarn,
              title: "异常报警",
              show: false,
              icon: "jingbao2",
            },
          },
          {
            path: "/NoticeManage",
            name: "NoticeManage",
            component: () => import("../views/wareHouse/NoticeManage.vue"),
            meta: {
              value: configuration.warehousenotify,
              title: "公告管理",
              show: false,
              icon: "gonggao",
            },
          },
          {
            path: "/storage",
            name: "storage",
            meta: {
              title: "仓储管理",
              show: false,
              icon: "cangchu",
            },
            children: [
              {
                path: "/projectadministration",
                name: "projectadministration",
                component: () =>
                  import("../views/wareHouse/projectadministration.vue"),
                meta: {
                  value: configuration.warehouseproject,
                  title: "项目管理",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },
              {
                path: "/warehousePage",
                name: "warehousePage",
                component: () => import("../views/wareHouse/warehousePage.vue"),
                meta: {
                  value: configuration.warehouse,
                  title: "仓库管理",
                  show: false,
                  icon: "cangchuyingyong",
                },
              },

              {
                path: "/classIfy",
                name: "classIfy",
                component: () => import("../views/wareHouse/classIfy.vue"),
                meta: {
                  value: configuration.warehousecategory,
                  title: "分类管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                },
              },
              {
                path: "/instRument",
                name: "instRument",
                component: () => import("../views/wareHouse/instRument.vue"),
                meta: {
                  value: configuration.warehouselib,
                  title: "物品库管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                },
              },
              {
                path: "/goodsManage",
                name: "goodsManage",
                component: () => import("../views/wareHouse/goodsManage.vue"),
                meta: {
                  value: configuration.warehousegood,
                  title: "货物管理",
                  show: false,
                  icon: "-_cangku",
                },
              },
              {
                path: "/outboundstatistics",
                name: "outboundstatistics",
                component: () =>
                  import("../views/wareHouse/outboundstatistics.vue"),
                meta: {
                  value: configuration.warehousecabinetordercount, //待定
                  title: "出库统计",
                  show: false,
                  icon: "-_cangku",
                },
              },
              {
                path: "/inventoryAlert",
                name: "inventoryAlert",
                component: () =>
                  import("../views/wareHouse/inventoryAlert.vue"),
                meta: {
                  value: configuration.warehousegoodswarn,
                  title: "库存预警",
                  show: true,
                  icon: "jingbaoshezhi-05",
                },
              },
              {
                path: "/circulateLog",
                name: "circulateLog",
                component: () => import("../views/wareHouse/circulateLog.vue"),
                meta: {
                  value: configuration.warehousecabinetorder,
                  title: "借还记录",
                  show: true,
                  icon: "jingbaoshezhi-05",
                },
              },
              {
                path: "/libLog",
                name: "libLog",
                meta: {
                  title: "退入库统计",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.warehousecabinetlog,
                },
                component: () => import("../views/wareHouse/libLog.vue"),
              },
            ],
          },
          {
            path: "/Application",
            name: "Application",
            meta: {
              title: "申请管理",
              show: false,
              icon: "shenqing",
            },
            children: [
              {
                path: "/ApplicationPersonage",
                name: "ApplicationPersonage",
                meta: {
                  title: "我的申请",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.warehouseapply,
                },
                component: () =>
                  import("../views/wareHouse/ApplicationPersonage.vue"),
              },
              {
                path: "/ApplicationAll",
                name: "ApplicationAll",
                meta: {
                  title: "审核管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.warehouseaudit,
                },
                component: () =>
                  import("../views/wareHouse/ApplicationAll.vue"),
              },

              {
                path: "/ApplicationDdetail",
                name: "ApplicationDdetail",
                meta: {
                  title: "我的申请",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: "",
                },
                component: () =>
                  import("../views/wareHouse/ApplicationDdetail.vue"),
              },

              {
                path: "/applicationProcedure",
                name: "applicationProcedure",
                meta: {
                  title: "申请流程管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.warehouseworkflow,
                },
                component: () =>
                  import("../views/wareHouse/applicationProcedure.vue"),
              },
            ],
          },
          {
            path: "/insToll",
            name: "insToll",
            meta: {
              title: "设备管理",
              show: false,
              icon: "shebei",
            },
            children: [
              {
                path: "/toolCabinet",
                name: "toolCabinet",
                component: () => import("../views/wareHouse/toolCabinet.vue"),
                meta: {
                  value: configuration.warehousecabinetgroup,
                  title: "智能终端",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                },
              },
              {
                path: "/toolCabinets",
                name: "toolCabinets",
                component: () => import("../views/wareHouse/toolCabinets.vue"),
                meta: {
                  value: configuration.warehousecabinet,
                  title: "智能设备",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                },
              },
              {
                path: "/gateMachine",
                name: "gateMachine",
                component: () => import("../views/wareHouse/gateMachine.vue"),
                meta: {
                  value: configuration.warehousegate,
                  title: "门禁管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                },
              },
              {
                path: "/trafficRecords",
                name: "trafficRecords",
                component: () =>
                  import("../views/wareHouse/trafficRecords.vue"),
                meta: {
                  value: configuration.warehousegatelog,
                  title: "通行记录",
                  show: false,
                  icon: "liucheng",
                },
              },
            ],
          },
          {
            path: "/warehousestatis",
            name: "warehousestatis",
            component: () => import("../views/wareHouse/warehousestatis.vue"),
            meta: {
              value: configuration.warehousestatis,
              title: "数据统计",
              show: false,
              icon: "tongji",
            },
          },
          {
            path: "/inventoryrecord",
            name: "inventoryrecord",
            component: () => import("../views/wareHouse/inventoryrecord.vue"),
            meta: {
              value: configuration.warehouseinventorylog,
              title: "盘点记录",
              show: false,
              icon: "pandian",
            },
          },
          {
            path: "/storageScreen",
            name: "storageScreen",
            component: () => import("../views/dataScreen/storageScreen.vue"),
            meta: {
              value: "",
              title: "仓储大屏",
              show: true,
              icon: "daping",
              large: "storageScreen",
            },
          },
        ],
      },
      {
        path: "/classgrade",
        name: "classgrade",
        redirect: "/classGradevideo",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 728,
          type: "电子班牌",
        },
        children: [
          {
            path: "/classGrade",
            name: "classGrade",
            meta: {
              title: "班级管理",
              more: true,
              show: false,
              icon: "class",
            },
            children: [
              {
                path: "/classGradevideo",
                name: "classGradevideo",
                meta: {
                  title: "班级视频",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo,
                },
                component: () => import("../views/banPai/classGradevideo.vue"),
              },
              {
                path: "/classGradeelegant",
                name: "classGradeelegant",
                meta: {
                  title: "班级风采",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardnews,
                },
                component: () =>
                  import("../views/banPai/classGradeelegant.vue"),
              },
              // {
              //   path: "/banPaiAffiche",
              //   name: "banPaiAffiche",
              //   meta: {
              //     title: "通知公告",
              //     show: false,
              //     icon: "yidongyunkongzhitaiicon01",
              //     value: configuration.classnotify,
              //   },
              //   component: () => import("../views/basicdata/banPaiAffiche.vue"),
              // },
            ],
          },
          {
            path: "/schoolGrade",
            name: "schoolGrade",
            meta: {
              title: "校园管理",
              // more: true,
              show: false,
              icon: "school",
            },
            children: [
              {
                path: "/schoolGradevideo",
                name: "schoolGradevideo",
                meta: {
                  title: "校园视频",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardschoolvideo,
                },
                component: () => import("../views/banPai/schoolGradevideo.vue"),
              },
              {
                path: "/schoolGradeelegant",
                name: "schoolGradeelegant",
                meta: {
                  title: "校园风采",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardschoolnews,
                },
                component: () =>
                  import("../views/banPai/schoolGradeelegant.vue"),
              },
            ],
          },
          {
            path: "/studentGrade",
            name: "studentGrade",
            meta: {
              title: "学生管理",
              // more: true,
              show: false,
              icon: "xuesheng",
            },
            children: [
              {
                path: "/studentlGood",
                name: "studentlGood",
                meta: {
                  title: "优秀学生",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardclassstu,
                },
                component: () => import("../views/banPai/studentlGood.vue"),
              },
              {
                path: "/studentWorks",
                name: "studentWorks",
                meta: {
                  title: "学生作品",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardclassopus,
                },
                component: () => import("../views/banPai/studentWorks.vue"),
              },
            ],
          },
          {
            path: "/screenGrade",
            name: "screenGrade",
            meta: {
              title: "屏幕管理",
              // more: true,
              show: false,
              icon: "screen",
            },
            children: [
              {
                path: "/screenProtection",
                name: "screenProtection",
                meta: {
                  title: "图片屏保",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardscreenimage,
                },
                component: () => import("../views/banPai/screenProtection.vue"),
              },
              {
                path: "/screenProtections",
                name: "screenProtections",
                meta: {
                  title: "视频屏保",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardscreenvideo,
                },
                component: () =>
                  import("../views/banPai/screenProtections.vue"),
              },
              {
                path: "/screenSuper",
                name: "screenSuper",
                meta: {
                  title: "霸屏管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardscreendisable,
                },
                component: () => import("../views/banPai/screenSuper.vue"),
              },
            ],
          },
          {
            path: "/systemAnnouncement",
            name: "systemAnnouncement",
            meta: {
              title: "系统公告",
              show: false,
              icon: "gonggao",
              value: configuration.ecardnotify,
            },
            component: () => import("../views/banPai/systemAnnouncement.vue"),
          },
          {
            path: "/banPai",
            name: "banPai",
            meta: {
              title: "班牌管理",
              show: false,
              icon: "banpai",
              value: configuration.ecard,
            },
            component: () => import("../views/banPai/banPai.vue"),
          },
        ],
      },
      {
        path: "/educational",
        name: "educational",
        redirect: "/classGradevideo",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 789,
          type: "教务中心",
        },
        children: [
          {
            path: "/attendclasstime",
            name: "attendclasstime",
            meta: {
              title: "作息时间维护",
              show: false,
              icon: "times",
              value: configuration.edutimetableset,
            },
            component: () => import("../views/educational/attendclasstime.vue"),
          },
          {
            path: "/subject",
            name: "subject",
            meta: {
              title: "学科管理",
              show: false,
              icon: "xueke",
              value: configuration.edusubject,
            },
            component: () => import("../views/educational/subject.vue"),
          },
          {
            path: "/course",
            name: "course",
            meta: {
              title: "课程管理",
              show: false,
              icon: "kecheng",
              value: configuration.educourse,
            },
            component: () => import("../views/educational/course.vue"),
          },
          {
            path: "/coursesScheduling",
            name: "coursesScheduling",
            meta: {
              title: "排课",
              show: false,
              icon: "paike",
              value: configuration.selectclass,
            },
            component: () =>
              import("../views/educational/coursesScheduling.vue"),
          },
          {
            path: "/checkingGrade",
            name: "checkingGrade",
            meta: {
              title: "考勤管理",
              more: true,
              show: false,
              icon: "kaoqing",
            },
            children: [
              {
                path: "/allClasschecking",
                name: "allClasschecking",
                meta: {
                  title: "全班考勤",
                  show: false,
                  icon: "classke",
                  value: configuration.eduattendance,
                },
                component: () =>
                  import("../views/educational/allClasschecking.vue"),
              },
            ],
          },

          {
            path: "/myCourse",
            name: "myCourse",
            meta: {
              title: "我的课表",
              show: false,
              icon: "myke",
              value: configuration.educoursetable,
            },
            component: () => import("../views/educational/myCourse.vue"),
          },
          {
            path: "/classCourse",
            name: "classCourse",
            meta: {
              title: "班级课表",
              show: false,
              icon: "classke",
              value: configuration.educoursetable,
            },
            component: () => import("../views/educational/classCourse.vue"),
          },

          {
            path: "/askleave",
            name: "askleave",
            meta: {
              title: "请假管理",
              show: false,
              icon: "qingjias",
              value: configuration.dormstudentleave,
            },
            component: () => import("../views/educational/askleave.vue"),
          },

          {
            path: "/signin",
            name: "signin",
            meta: {
              title: "班主任签到",
              show: false,
              icon: "qiandao",
              value: configuration.eduteachersign,
            },
            component: () => import("../views/educational/signin.vue"),
          },
        ],
      },
      {
        path: "/trainingRoom",
        name: "trainingRoom",
        redirect: "/Roommagement",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 402,
          type: "实训室",
        },
        children: [
          {
            path: "/Roommagement",
            name: "Roommagement",
            meta: {
              title: "实训室管理",
              show: false,
              icon: "shixunshi",
              value: configuration.training,
            },
            component: () => import("../views/trainingRoom/Roommagement.vue"),
          },
          {
            path: "/callpolice",
            name: "callpolice",
            meta: {
              title: "报警记录",
              show: false,
              icon: "baojing",
              value: configuration.trainingdevicewarnlog,
            },
            component: () => import("../views/trainingRoom/callpolice.vue"),
          },
          {
            path: "/equipment",
            name: "equipment",
            meta: {
              title: "设备管理",
              show: false,
              icon: "shebei",
              value: configuration.trainingdevice,
            },
            component: () => import("../views/trainingRoom/equipment.vue"),
          },
        ],
      },
      {
        path: "/gotobedg",
        name: "gotobedg",
        redirect: "/Roommagement",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 266,
          type: "归寝管理",
        },
        children: [
          {
            path: "/dataAanalysise",
            name: "dataAanalysise",
            meta: {
              title: "数据分析",
              show: true,
              icon: "shujufenxi",
            },
            component: () => import("../views/gotobedg/dataAanalysise.vue"),
          },
          {
            path: "/gotobe",
            name: "gotobe",
            meta: {
              title: "寝室管理",
              // more: true,
              show: false,
              icon: "lou",
            },

            children: [
              {
                path: "/gotobebuilde",
                name: "gotobebuilde",
                meta: {
                  title: "楼栋信息",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dormbuilding,
                },
                component: () => import("../views/gotobedg/gotobebuilde.vue"),
              },

              {
                path: "/gotobedormitory",
                name: "gotobedormitory",
                meta: {
                  title: "寝室管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dorm,
                },
                component: () =>
                  import("../views/gotobedg/gotobedormitory.vue"),
              },
              {
                path: "/gotobedormitorytype",
                name: "gotobedormitorytype",
                meta: {
                  title: "寝室类型",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dodormtypem,
                },
                component: () =>
                  import("../views/gotobedg/gotobedormitorytype.vue"),
              },
              {
                path: "/bedchamberbeds",
                name: "bedchamberbeds",
                meta: {
                  title: "床位管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dormbed,
                },
                component: () => import("../views/gotobedg/bedchamberbeds.vue"),
              },
              // {
              //   path: "/gotobeds",
              //   name: "gotobeds",
              //   meta: {
              //     title: "归寝管理",
              //     show: false,s
              //     icon: "yidongyunkongzhitaiicon01",
              //     value: configuration.ecardclassstu, //待定
              //   },
              //   component: () => import("../views/gotobedg/gotobeds.vue"),
              // },
            ],
          },
          {
            path: "/gotobedgstudent",
            name: "gotobedgstudent",
            meta: {
              title: "学生管理",
              // more: true,
              show: false,
              icon: "xuesheng",
            },
            children: [
              // {
              //   path: "/studenttemperature",
              //   name: "studenttemperature",
              //   meta: {
              //     title: "体温异常",
              //     show: false,
              //     icon: "yidongyunkongzhitaiicon01",
              //     value: configuration.ecardclassstu, //待定
              //   },
              //   component: () =>
              //     import("../views/gotobedg/studenttemperature.vue"),
              // },
              // {
              //   path: "/studentmessage",
              //   name: "studentmessage",
              //   meta: {
              //     title: "学生信息",
              //     show: false,
              //     icon: "yidongyunkongzhitaiicon01",
              //     value: configuration.ecardclassstu, //待定
              //   },
              //   component: () => import("../views/gotobedg/studentmessage.vue"),
              // },
              {
                path: "/studentdormitoryarrange",
                name: "studentdormitoryarrange",
                meta: {
                  title: "学生寝室安排",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dormarrange,
                },
                component: () =>
                  import("../views/gotobedg/studentdormitoryarrange.vue"),
              },
              {
                path: "/unstudentdormitory",
                name: "unstudentdormitory",
                meta: {
                  title: "退寝管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dormquit,
                },
                component: () =>
                  import("../views/gotobedg/unstudentdormitory.vue"),
              },
              {
                path: "/dormitorydetail",
                name: "dormitorydetail",
                meta: {
                  title: "寝室明细",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dormmember,
                },
                component: () =>
                  import("../views/gotobedg/dormitorydetail.vue"),
              },
              {
                path: "/dormitorygtobed",
                name: "dormitorygtobed",
                meta: {
                  title: "寝室统计归寝",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dormattendance,
                },
                component: () =>
                  import("../views/gotobedg/dormitorygtobed.vue"),
              },
              {
                path: "/Bedroomdetails",
                name: "Bedroomdetails",
                meta: {
                  title: "寝室归寝明细",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dormattendancelog,
                },
                component: () => import("../views/gotobedg/Bedroomdetails.vue"),
              },
            ],
          },
          {
            path: "/gotobedgfacility",
            name: "gotobedgfacility",
            meta: {
              title: "放行机管理",
              // more: true,
              show: false,
              icon: "shebei",
            },
            children: [
              {
                path: "/facilitylocation",
                name: "facilitylocation",
                meta: {
                  title: "设备位置",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dormdeviceposition,
                },
                component: () =>
                  import("../views/gotobedg/facilitylocation.vue"),
              },
              {
                path: "/facilitymachine",
                name: "facilitymachine",
                meta: {
                  title: "大屏管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dormdevicerelease,
                },
                component: () =>
                  import("../views/gotobedg/facilitymachine.vue"),
              },
              {
                path: "/facilitygatemachine",
                name: "facilitygatemachine",
                meta: {
                  title: "闸机管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.dormgate,
                },
                component: () =>
                  import("../views/gotobedg/facilitygatemachine.vue"),
              },
            ],
          },
          {
            path: "/industrious",
            name: "industrious",
            meta: {
              title: "勤彩",
              // more: true,
              show: false,
              icon: "shebei",
            },
            children: [
              {
                path: "/gotobedgequipment",
                name: "gotobedgequipment",
                meta: {
                  title: "设备管理",
                  show: false,
                  icon: "shebei",
                  value: configuration.dormGateOld,
                },
                component: () =>
                  import("../views/gotobedg/gotobedgequipment.vue"),
              },
              {
                path: "/comeout",
                name: "comeout",
                meta: {
                  title: "出入记录",
                  show: false,
                  icon: "shebei",
                  value: configuration.dormGateOld,
                },
                component: () => import("../views/gotobedg/comeout.vue"),
              },
            ],
          },
          {
            path: "/device_management",
            name: "device_management",
            meta: {
              title: "设备管理（海康）",
              // more: true,
              show: false,
              icon: "shebei",
            },
            children: [
              {
                path: "/device_managementhk",
                name: "device_managementhk",
                meta: {
                  title: "设备管理",
                  show: false,
                  icon: "shebei",
                  value: configuration.dordormgatehik,
                },
                component: () =>
                  import("../views/gotobedg/device_managementhk.vue"),
              },
              {
                path: "/travel_record",
                name: "travel_record",
                meta: {
                  title: "通行记录",
                  show: false,
                  icon: "shebei",
                  value: configuration.dordormgatehiklog,
                },
                component: () => import("../views/gotobedg/travel_record.vue"),
              },
            ],
          },
          {
            path: "/gotobedgannouncement",
            name: "gotobedgannouncement",
            meta: {
              title: "公告",
              show: false,
              icon: "gonggao",
              value: configuration.dormnotify,
            },
            component: () =>
              import("../views/gotobedg/gotobedgannouncement.vue"),
          },
          // {
          //   path: "/bedroomScreen",
          //   name: "bedroomScreen",
          //   meta: {
          //     title: "可视化大屏",
          //     show: false,
          //     icon: "daping",
          //     value: configuration.ecardvideo, //待定
          //   },
          //   component: () => import("../views/gotobedg/bedroomScreen.vue"),
          // },
          {
            path: "/bedroomScreen",
            name: "bedroomScreen",
            component: () => import("../views/gotobedg/bedroomScreen.vue"),
            meta: {
              value: "",
              title: "归寝大屏",
              show: true,
              icon: "daping",
              large: "bedroomScreenn",
            },
          },
          // {
          //   path: "/gotobedgversions",
          //   name: "gotobedgversions",
          //   meta: {
          //     title: "版本管理",
          //     show: false,
          //     icon: "yidongyunkongzhitaiicon01",
          //     value: configuration.edusubject, //待定
          //   },
          //   component: () => import("../views/gotobedg/gotobedgversions.vue"),
          // },
        ],
      },
      {
        path: "/teacherchecking",
        name: "teacherchecking",
        redirect: "/Roommagement",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 986,
          type: "教职工考勤",
        },

        children: [
          {
            path: "/teadataAnalysis",
            name: "teadataAnalysis",
            meta: {
              title: "数据分析",
              show: false,
              icon: "shujufenxi",
              value: configuration.ecardvideo, //待定
            },
            component: () => import("../views/checking/teadataAnalysis.vue"),
          },
          {
            path: "/checkingfold",
            name: "checkingfold",
            meta: {
              title: "考勤打卡管理",
              more: true,
              show: false,
              icon: "laoshidaka",
            },
            children: [
              {
                path: "/updownchecking",
                name: "updownchecking",
                meta: {
                  title: "上下班打卡管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () => import("../views/checking/updownchecking.vue"),
              },
              // {
              //   path: "/fallibility",
              //   name: "fallibility",
              //   meta: {
              //     title: "请假规则管理",
              //     show: false,
              //     icon: "yidongyunkongzhitaiicon01",
              //     value: configuration.ecardvideo, //待定
              //   },
              //   component: () => import("../views/checking/fallibility.vue"),
              // },
              {
                path: "/leaveRule",
                name: "leaveRule",
                meta: {
                  title: "请假规则管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () => import("../views/checking/leaveRule.vue"),
              },
              {
                path: "/replacementRole",
                name: "replacementRole",
                meta: {
                  title: "补卡规则管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () =>
                  import("../views/checking/replacementRole.vue"),
              },
              {
                path: "/attendanceEquipment",
                name: "attendanceEquipment",
                meta: {
                  title: "考勤设备管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () =>
                  import("../views/checking/attendanceEquipment.vue"),
              },
              {
                path: "/leaveRecord",
                name: "leaveRecord",
                meta: {
                  title: "请假记录管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () => import("../views/checking/leaveRecord.vue"),
              },
              {
                path: "/myAskleave",
                name: "myAskleave",
                meta: {
                  title: "我的请假",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () => import("../views/checking/myAskleave.vue"),
              },
              {
                path: "/myAskleaveaudit",
                name: "myAskleaveaudit",
                meta: {
                  title: "我的请假审核",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () =>
                  import("../views/checking/myAskleaveaudit.vue"),
              },
              {
                path: "/cardReplacement",
                name: "cardReplacement",
                meta: {
                  title: "补卡记录管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () =>
                  import("../views/checking/cardReplacement.vue"),
              },
              {
                path: "/mycardReplacement",
                name: "mycardReplacement",
                meta: {
                  title: "我的补卡",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () =>
                  import("../views/checking/mycardReplacement.vue"),
              },
              {
                path: "/myreplacementApply",
                name: "myreplacementApply",
                meta: {
                  title: "我的补卡审核",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () =>
                  import("../views/checking/myreplacementApply.vue"),
              },
            ],
          },
          {
            path: "/checkingstatistics",
            name: "checkingstatistics",
            meta: {
              title: "考勤统计管理",
              more: true,
              show: false,
              icon: "laoshitongji",
            },
            children: [
              {
                path: "/statisticsDay",
                name: "statisticsDay",
                meta: {
                  title: "考勤日报统计",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () => import("../views/checking/statisticsday.vue"),
              },
              {
                path: "/personalStatistics",
                name: "personalStatistics",
                meta: {
                  title: "考勤月报统计",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.ecardvideo, //待定
                },
                component: () =>
                  import("../views/checking/personalStatistics.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/AImanagement",
        name: "AImanagement",
        redirect: "/Roommagement",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 1508,
          type: "AI评分",
        },
        children: [
          {
            path: "/aidataAnalysis",
            name: "aidataAnalysis",
            meta: {
              title: "数据分析",
              show: true,
              icon: "shujufenxi",
              value: configuration.ecardvideo, //待定
            },
            component: () => import("../views/AImanagement/aidataAnalysis.vue"),
          },
          {
            path: "/aistatistics",
            name: "aistatistics",
            meta: {
              title: "实训室管理",
              more: true,
              show: false,
              icon: "shixunshi",
            },
            children: [
              {
                path: "/traningroom",
                name: "traningroom",
                meta: {
                  title: "实训室管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjietraining,
                },
                component: () =>
                  import("../views/AImanagement/traningroom.vue"),
              },
              {
                path: "/tranningWorkstation",
                name: "tranningWorkstation",
                meta: {
                  title: "工位管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjieposition,
                },
                component: () =>
                  import("../views/AImanagement/tranningWorkstation.vue"),
              },
              {
                path: "/Weldingmachine",
                name: "Weldingmachine",
                meta: {
                  title: "设备管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjietrainingdevice,
                },
                component: () =>
                  import("../views/AImanagement/Weldingmachine.vue"),
              },
              {
                path: "/banpaimanagent",
                name: "banpaimanagent",
                meta: {
                  title: "班牌管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjieecard,
                },
                component: () =>
                  import("../views/AImanagement/banpaimanagent.vue"),
              },
              {
                path: "/cameramanagent",
                name: "cameramanagent",
                meta: {
                  title: "摄像头管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjiecamera,
                },
                component: () =>
                  import("../views/AImanagement/cameramanagent.vue"),
              },
            ],
          },
          // {
          //   path: "/aiprojectmanagement",
          //   name: "aiprojectmanagement",
          //   meta: {
          //     title: "项目管理",
          //     more: true,
          //     show: false,
          //     icon: "xiangmu",
          //   },
          //   children: [
          //     {
          //       path: "/projectmanagement",
          //       name: "projectmanagement",
          //       meta: {
          //         title: "项目管理",
          //         show: false,
          //         icon: "yidongyunkongzhitaiicon01",
          //         value: configuration.ecardvideo, //待定
          //       },
          //       component: () =>
          //         import("../views/AImanagement/projectmanagement.vue"),
          //     },
          //     {
          //       path: "/Professionalcompetence",
          //       name: "Professionalcompetence",
          //       meta: {
          //         title: "职业素养评分标准",
          //         show: false,
          //         icon: "yidongyunkongzhitaiicon01",
          //         value: configuration.ecardvideo, //待定
          //       },
          //       component: () =>
          //         import("../views/AImanagement/Professionalcompetence.vue"),
          //     },
          //     {
          //       path: "/IoTscoringcriteria",
          //       name: "IoTscoringcriteria",
          //       meta: {
          //         title: "物联网评分标准",
          //         show: false,
          //         icon: "yidongyunkongzhitaiicon01",
          //         value: configuration.ecardvideo, //待定
          //       },
          //       component: () =>
          //         import("../views/AImanagement/IoTscoringcriteria.vue"),
          //     },
          //     {
          //       path: "/Imagestandards",
          //       name: "Imagestandards",
          //       meta: {
          //         title: "图像识别标准",
          //         show: false,
          //         icon: "yidongyunkongzhitaiicon01",
          //         value: configuration.ecardvideo, //待定
          //       },
          //       component: () =>
          //         import("../views/AImanagement/Imagestandards.vue"),
          //     },
          //   ],
          // },
          // {
          //   path: "/aiExamtasks",
          //   name: "aiExamtasks",
          //   meta: {
          //     title: "考试任务管理",
          //     more: true,
          //     show: false,
          //     icon: "kaoshi",
          //   },
          //   children: [
          //     {
          //       path: "/Examtasks",
          //       name: "Examtasks",
          //       meta: {
          //         title: "考试任务管理",
          //         show: false,
          //         icon: "yidongyunkongzhitaiicon01",
          //         value: configuration.ecardvideo, //待定
          //       },
          //       component: () => import("../views/AImanagement/Examtasks.vue"),
          //     },
          //   ],
          // },
          // {
          //   path: "/aiclassExamtasks",
          //   name: "aiclassExamtasks",
          //   meta: {
          //     title: "班级任务管理",
          //     more: true,
          //     show: false,
          //     icon: "kaoshi",
          //   },
          //   children: [
          //     {
          //       path: "/aiclassExamtasks",
          //       name: "aiclassExamtasks",
          //       meta: {
          //         title: "班级任务管理",
          //         show: false,
          //         icon: "yidongyunkongzhitaiicon01",
          //         value: configuration.ecardvideo, //待定
          //       },
          //       component: () =>
          //         import("../views/AImanagement/aiclassExamtasks.vue"),
          //     },
          //   ],
          // },
          {
            path: "/aibanner",
            name: "aibanner",
            meta: {
              title: "banner设置",
              more: true,
              show: false,
              icon: "banner",
            },
            children: [
              {
                path: "/aibanner",
                name: "aibanner",
                meta: {
                  title: "banner设置",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjiebanner,
                },
                component: () => import("../views/AImanagement/aibanner.vue"),
              },
            ],
          },
          {
            path: "/aiPublishexamtasks",
            name: "aiPublishexamtasks",
            meta: {
              title: "项目管理",
              more: true,
              show: false,
              icon: "fabu",
            },
            children: [
              {
                path: "/Publishexamtasks",
                name: "Publishexamtasks",
                meta: {
                  title: "任务管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjietask,
                },
                component: () =>
                  import("../views/AImanagement/Publishexamtasks.vue"),
              },
              {
                path: "/Venuemanagement",
                name: "Venuemanagement",
                meta: {
                  title: "工艺管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjietechnology,
                },
                component: () =>
                  import("../views/AImanagement/Venuemanagement.vue"),
              },
              {
                path: "/Appealreview",
                name: "Appealreview",
                meta: {
                  title: "历史任务",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.taskclass,
                },
                component: () =>
                  import("../views/AImanagement/Appealreview.vue"),
              },
              {
                path: "/projects",
                name: "projects",
                meta: {
                  title: "评分标准",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjieaiscoregroup,
                },
                component: () => import("../views/AImanagement/projects.vue"),
              },
            ],
          },
          // {
          //   path: "/aiAppealreview",
          //   name: "aiAppealreview",
          //   meta: {
          //     title: "申诉审核管理",
          //     more: true,
          //     show: false,
          //     icon: "shensu",
          //   },
          //   children: [
          //     {
          //       path: "/Appealreview",
          //       name: "Appealreview",
          //       meta: {
          //         title: "申诉审核",
          //         show: false,
          //         icon: "yidongyunkongzhitaiicon01",
          //         value: configuration.ecardvideo, //待定
          //       },
          //       component: () =>
          //         import("../views/AImanagement/Appealreview.vue"),
          //     },
          //     {
          //       path: "/Auditrecords",
          //       name: "Auditrecords",
          //       meta: {
          //         title: "审核记录",
          //         show: false,
          //         icon: "yidongyunkongzhitaiicon01",
          //         value: configuration.ecardvideo, //待定
          //       },
          //       component: () =>
          //         import("../views/AImanagement/Auditrecords.vue"),
          //     },
          //   ],
          // },
          {
            path: "/airecord",
            name: "airecord",
            meta: {
              title: "实训/考试记录管理",
              more: true,
              show: false,
              icon: "shixun",
            },
            children: [
              {
                path: "/Videorecording",
                name: "Videorecording",
                meta: {
                  title: "视频记录",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjievideolog,
                },
                component: () =>
                  import("../views/AImanagement/Videorecording.vue"),
              },
              {
                path: "/Scorerecord",
                name: "Scorerecord",
                meta: {
                  title: "成绩记录",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.hanjieresultlog,
                },
                component: () =>
                  import("../views/AImanagement/Scorerecord.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/electricityconsumption",
        name: "electricityconsumption",
        redirect: "/Roommagement",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 1650,
          type: "能耗管理",
        },
        children: [
          {
            path: "/electricityconsumption",
            name: "electricityconsumption",
            meta: {
              title: "水能耗",
              more: true,
              show: false,
              icon: "shixunshi",
            },
            children: [
              {
                path: "/electricityconsumption",
                name: "electricityconsumption",
                meta: {
                  title: "水表管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energywatermeter,
                },
                component: () =>
                  import("../views/consumption/electricityconsumption.vue"),
              },
              {
                path: "/Wateroperation",
                name: "Wateroperation",
                meta: {
                  title: "操作记录",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energywatermetercontrollog,
                },
                component: () =>
                  import("../views/consumption/Wateroperation.vue"),
              },
              {
                path: "/Scheduledtasks",
                name: "Scheduledtasks",
                meta: {
                  title: "定时任务",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energywatermetercrontab,
                },
                component: () =>
                  import("../views/consumption/Scheduledtasks.vue"),
              },
              {
                path: "/Warningconfig",
                name: "Warningconfig",
                meta: {
                  title: "预警配置",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energywatermeterWarnconfig,
                },
                component: () =>
                  import("../views/consumption/Warningconfig.vue"),
              },
              {
                path: "/Warningrecord",
                name: "Warningrecord",
                meta: {
                  title: "预警记录",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energywatermeterwarnlog,
                },
                component: () =>
                  import("../views/consumption/Warningrecord.vue"),
              },
            ],
          },
          {
            path: "/Waterenergy",
            name: "Waterenergy",
            meta: {
              title: "电能耗",
              more: true,
              show: false,
              icon: "banner",
            },
            children: [
              {
                path: "/Waterenergy",
                name: "Waterenergy",
                meta: {
                  title: "采集器管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energyelecmeter,
                },
                component: () => import("../views/consumption/Waterenergy.vue"),
              },
              {
                path: "/emptyopening",
                name: "emptyopening",
                meta: {
                  title: "空开管理",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energyelecmeterchild,
                },
                component: () =>
                  import("../views/consumption/emptyopening.vue"),
              },
              {
                path: "/operationrecord",
                name: "operationrecord",
                meta: {
                  title: "操作记录",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energyelecmetercontrollog,
                },
                component: () =>
                  import("../views/consumption/operationrecord.vue"),
              },
              {
                path: "/eventrecord",
                name: "eventrecord",
                meta: {
                  title: "事件记录",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energyelecmeterevent,
                },
                component: () => import("../views/consumption/eventrecord.vue"),
              },
              {
                path: "/cheduledtaskse",
                name: "cheduledtaskse",
                meta: {
                  title: "定时任务",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energyelecmetercrontab,
                },
                component: () =>
                  import("../views/consumption/cheduledtaskse.vue"),
              },
              {
                path: "/warningconfiguration",
                name: "warningconfiguration",
                meta: {
                  title: "预警配置",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energyelecmeterwarnconfig,
                },
                component: () =>
                  import("../views/consumption/warningconfiguration.vue"),
              },
              {
                path: "/warningrecords",
                name: "warningrecords",
                meta: {
                  title: "预警记录",
                  show: false,
                  icon: "yidongyunkongzhitaiicon01",
                  value: configuration.energyelecmeterwarnlog,
                },
                component: () =>
                  import("../views/consumption/warningrecords.vue"),
              },
            ],
          },
          {
            path: "/energyconsumptiongescreen",
            name: "energyconsumptiongescreen",
            component: () =>
              import("../views/smartClassroom/energyconsumptiongescreen.vue"),
            meta: {
              value: "",
              title: "数据大屏",
              show: true,
              icon: "daping",
              large: "energyconsumptiongescreen",
            },
          },
        ],
      },
      {
        path: "/resourceall",
        name: "resourceall",
        redirect: "/Roommagement",
        component: () => import("../views/HomeView.vue"),
        meta: {
          id: 666,
          type: "资源管理",
        },
        children: [
          {
            path: "/myresource",
            name: "myresource",
            meta: {
              title: "我的资源",
              show: true,
              icon: "myresource",
              value: "",
            },
            component: () => import("../views/resource/myresource.vue"),
          },
          {
            path: "/publicresource",
            name: "publicresource",
            meta: {
              title: "公共资源",
              show: true,
              icon: "publicresource",
              value: "",
            },
            component: () => import("../views/resource/publicresource.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/error/erRor.vue"),
  },
  {
    path: "/storageScreen",
    name: "storageScreen",
    meta: {
      name: "大屏",
    },
    component: () => import("../views/dataScreen/storageScreen.vue"),
  },
  {
    path: "/screenSupers",
    name: "screenSupers",
    component: () => import("../views/banPai/screenSupers.vue"),
  },
  {
    path: "/bedroomScreenn",
    name: "bedroomScreenn",
    meta: {
      name: "大屏",
    },
    component: () => import("../views/gotobedg/bedroomScreen.vue"),
  },
  {
    path: "/classLargescreen",
    name: "classLargescreen",
    meta: {
      name: "大屏",
    },
    component: () => import("../views/smartClassroom/classLargescreen.vue"),
  },
  {
    path: "/sxs",
    name: "sxs",
    meta: {
      name: "大屏",
    },
    component: () =>
      import("../views/wisdomtrainingroom/wisdomtrainingroomScreen.vue"),
  },
  {
    path: "/energyconsumptiongescreen",
    name: "energyconsumptiongescreen",
    meta: {
      name: "大屏",
    },
    component: () =>
      import("../views/smartClassroom/energyconsumptiongescreen.vue"),
  },
  {
    path: "/AIAllpage",
    name: "AIAllpage",
    component: () => import("../views/AIAll/AIAllpage.vue"),
    children: [
      {
        path: "/AIAllpagehome",
        name: "AIAllpagehome",
        component: () => import("../views/AIAll/AIAllpagehome.vue"),
      },
      {
        path: "/AIAnswerCorrection",
        name: "AIAnswerCorrection",
        component: () => import("../views/AIAll/AIAnswerCorrection.vue"),
      },
      {
        path: "/realtimeiNvigilation",
        name: "realtimeiNvigilation",
        component: () => import("../views/AIAll/realtimeiNvigilation.vue"),
      },
      {
        path: "/realtimeiNvigilationlog",
        name: "realtimeiNvigilationlog",
        component: () => import("../views/AIAll/realtimeiNvigilationlog.vue"),
      },
      {
        path: "/realtimeiNvigilationsee",
        name: "realtimeiNvigilationsee",
        component: () => import("../views/AIAll/realtimeiNvigilationsee.vue"),
      },
      {
        path: "/realtimeiNvigilationsees",
        name: "realtimeiNvigilationsees",
        component: () => import("../views/AIAll/realtimeiNvigilationsees.vue"),
      },
      {
        path: "/AIappeal",
        name: "AIappeal",
        component: () => import("../views/AIAll/AIappeal.vue"),
      },
      {
        path: "/AIappealrecord",
        name: "AIappealrecord",
        component: () => import("../views/AIAll/AIappealrecord.vue"),
      },
      {
        path: "/AIexamination",
        name: "AIexamination",
        component: () => import("../views/AIAll/AIexamination.vue"),
      },
      {
        path: "/AIclassranking",
        name: "AIclassranking",
        component: () => import("../views/AIAll/AIclassranking.vue"),
      },
      {
        path: "/AIimageupload",
        name: "AIimageupload",
        component: () => import("../views/AIAll/AIimageupload.vue"),
      },
      {
        path: "/AIimageuploads",
        name: "AIimageuploads",
        component: () => import("../views/AIAll/AIimageuploads.vue"),
      },
      {
        path: "/AIimageuploadlist",
        name: "AIimageuploadlist",
        component: () => import("../views/AIAll/AIimageuploadlist.vue"),
      },
      {
        path: "/AIimageuploadlists",
        name: "AIimageuploadlists",
        component: () => import("../views/AIAll/AIimageuploadlists.vue"),
      },
      {
        path: "/Ratinginformation",
        name: "Ratinginformation",
        component: () => import("../views/AIAll/Ratinginformation.vue"),
      },
      {
        path: "/AIsession",
        name: "AIsession",
        component: () => import("../views/AIAll/AIsession.vue"),
      },
      {
        path: "/AIsessions",
        name: "AIsessions",
        component: () => import("../views/AIAll/AIsessions.vue"),
      },
    ],
  },
];
export default routes;
